<template>
    <div>
        <div class="popup">
            <div class="popup-content">
                <div class="payment-loader">
                    <div class="loader-spinner">
                        <div class="circle-progress">
                            <svg class="progress" width="100" height="100">
                                
                                <circle class="progress-background" stroke="#161616" stroke-width="12" fill="transparent"
                                    r="40" cx="50" cy="50" />
                               
                                <circle class="progress-ring" stroke="#00BB81" stroke-width="12" fill="transparent" r="40"
                                    cx="50" cy="50"
                                    :style="{ strokeDasharray: circumference, strokeDashoffset: progressOffset }" />
                            </svg>
                            <div class="spinner-text">{{ formattedTime }}</div>
                        </div>
                    </div>
                    <p class="loading-primarytext">{{progresskey.payementProcess}}</p>
                    <p class="loading-text"> {{progresskey.paymentUnderprocess}}</p>
                    <div class="important-note-bg">
                    <p class="important-note">{{progresskey.dontrefreshkey}}  </p>                      
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            loaderDuration: 120,
            remainingTime: 120,
            countdownInterval: null,
            circumference: 2 * Math.PI * 40,
            progressOffset: 0,
            progresskey:{}
        };
    },
    computed: {
        formattedTime() {
            const minutes = Math.floor(this.remainingTime / 60);
            const seconds = this.remainingTime % 60;
            return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        },
    },
    mounted() {
        this.startCountdown();
    },
    created(){
    this.progresskey.payementProcess = this.$t('PaymentProcessing')
    this.progresskey.paymentUnderprocess = this.$t('PaymentProcesson2min')
    this.progresskey.dontrefreshkey = this.$t('ImportantNote')
    },
    methods: {
        startCountdown() {
            const intervalDuration = 1000;

            this.countdownInterval = setInterval(() => {
                this.remainingTime--;

                if (this.remainingTime <= 0) {
                    clearInterval(this.countdownInterval);
                    this.remainingTime = 0;
                }

                this.progressOffset = (1 - this.remainingTime / this.loaderDuration) * this.circumference;
            }, intervalDuration);
        },
    },
    beforeDestroy() {
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval);
        }
    },
};
</script>
  
<style scoped>
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.popup-content {
    background: #212121;
    padding: 1.5rem;
    border-radius: 10px;
    width: 40%;
    height: 55%;
}

.payment-loader {
    width: 100%;
    text-align: center;
}

.loader-spinner {
    width: 7rem;
    height: 7rem;
    margin: 0 auto;
    position: relative;
}

.circle-progress {
    position: relative;
}

.progress-ring {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    transition: stroke-dashoffset 1s linear;
}

.progress-background {
    position: absolute;
    top: 0;
    left: 0;
}

.spinner-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: #EBFFF1;
}

.loading-text {
    color: #FFFFFFB2;
    font-size: 0.7rem;
    margin-top: 0.6rem;
}

.loading-primarytext {
    color: #ffffff;
    font-weight: 600;
    font-size: 1rem;
    margin-top: 1.5rem;
}

.important-note-bg {
    background: linear-gradient(0deg, #161616, #161616), linear-gradient(0deg, rgba(255, 68, 68, 0.2), rgba(255, 68, 68, 0.2));
    width: 77%;
    margin: 0 auto;
    border-radius: 1.5rem;
}

.important-note {
    margin-top: 4rem;
    font-size: 0.5rem;
    color: #FF4444;
    font-weight: 600;
    padding: 0.3rem;
}

@media (max-width: 320px) {
    .popup-content {
        background: #212121;
        padding: 1.5rem;
        border-radius: 10px;
        width: 70%;
        height: 55%;
    }

    .important-note {
        margin-top: 1rem;
        font-size: 0.4rem;
        font-weight: 600;
        padding: 0.4rem;
    }
}

@media (min-width: 321px) and (max-width: 468px) {
    .popup-content {
        background: #212121;
        padding: 1.5rem;
        border-radius: 10px;
        width: 80%;
        height: 55%;
    }

    .important-note {
        margin-top: 2rem;
        font-size: 0.5rem;
    }
}

@media (min-width: 469px) and (max-width: 768px) {
    .popup-content {
        background: #212121;
        padding: 1.5rem;
        border-radius: 10px;
        width: 60%;
        height: 55%;
    }
}</style>
  